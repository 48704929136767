import { useEffect, useRef } from "react";
import { useAuthStore } from "../auth/auth-store";
import { useAppMenuStore } from "./studio-app-menu.store";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { useNavigate } from "react-router-dom";

export const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore((state) => state);
  const { bots, instances, programs } = useAppMenuStore((state) => state);

  return (
    <>
      <div className="pt-4 pb-6 w-full flex -items-center">
        <div className="text-3xl">
          Welcome {`${user?.displayName ?? user?.email ?? ""}`}
        </div>
      </div>
      <div>
        <div className="w-full flex items-center py-6">
          <div className="text-2xl">Instances</div>
          <div className="ml-4 mt-1 border-b w-full"></div>
        </div>
        <div className="flex flex-wrap gap-3">
          {Object.keys(instances).length > 0 ? (
            Object.entries(instances).map(([instanceId, instance]) => {
              return (
                <Card key={instanceId} className="w-[320px]">
                  <CardHeader>
                    <CardTitle>{instance.name}</CardTitle>
                    <CardDescription className="pt-4">
                      <div className="flex">
                        <div className="pl-1 pr-2 pb-2 font-thin">
                          Created at:
                        </div>
                        <div className="pl-1">
                          {instance?.createdAt &&
                          typeof instance?.createdAt === "object"
                            ? new Date(
                                instance?.createdAt?.seconds * 1000 +
                                  instance?.createdAt.nanoseconds / 1000000
                              ).toLocaleString()
                            : new Date(
                                parseInt(instance?.createdAt as string)
                              ).toLocaleString()}
                        </div>
                      </div>
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="flex">
                      <div className="pl-1 pr-2 pb-2 font-thin">Bot:</div>
                      <div className="flex flex-col">
                        <div className="pl-1">{instance.name}</div>
                      </div>
                    </div>
                  </CardContent>
                  <CardFooter className="flex justify-center w-full">
                    <Button
                      variant="outline"
                      className="hover:bg-zinc-50"
                      onClick={() => {
                        navigate(
                          `/workspace/instances?id=${instanceId}&action=read`
                        );
                      }}
                    >
                      Open
                    </Button>
                  </CardFooter>
                </Card>
              );
            })
          ) : (
            <div className="text-center w-full">
              No instances found. Deploy a bot to get some instance running.
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="w-full flex items-center py-6">
          <div className="text-2xl">Bots</div>
          <div className="ml-4 mt-1 border-b w-full"></div>
        </div>
        <div className="flex flex-wrap gap-3">
          {Object.keys(bots).length > 0 ? (
            Object.entries(bots).map(([botId, bot]) => {
              return (
                <Card key={botId} className="w-[320px]">
                  <CardHeader>
                    <CardTitle>{bot.name}</CardTitle>
                    <CardDescription className="pt-4">
                      Created at:{" "}
                      {bot?.createdAt && typeof bot?.createdAt === "object"
                        ? new Date(
                            bot?.createdAt?.seconds * 1000 +
                              bot?.createdAt.nanoseconds / 1000000
                          ).toLocaleString()
                        : new Date(
                            parseInt(bot?.createdAt as string)
                          ).toLocaleString()}
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <div className="flex">
                      <div className="pl-1 pr-2 pb-2 font-thin">Program:</div>
                      <div className="flex flex-col">
                        <div className="pl-1">
                          {programs[bot.programId]?.name}
                        </div>
                      </div>
                    </div>
                  </CardContent>
                  <CardFooter className="flex justify-center w-full">
                    <Button
                      variant="outline"
                      className="hover:bg-zinc-50"
                      onClick={() => {
                        navigate(`/workspace/bots?id=${botId}&action=read`);
                      }}
                    >
                      Open
                    </Button>
                  </CardFooter>
                </Card>
              );
            })
          ) : (
            <div className="text-center w-full">No bots found</div>
          )}
        </div>
      </div>
    </>
  );
};
