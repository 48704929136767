import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Icons } from "@/components/ui/icons";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { cn } from "@/components/ui/utils";
import { useAuthStore } from "../auth-store";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function SignInForm({ className, ...props }: UserAuthFormProps) {
  const { signIn, signInWithGithub, user, loading } = useAuthStore(
    (state) => state
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("User", user);
    if (user) {
      navigate("/workspace");
    }
  }, [user]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <div className="grid gap-2">
        <div className="grid gap-1">
          <Label className="sr-only" htmlFor="email">
            Email
          </Label>
          <p className="text-sm text-muted-foreground font-bold tracking-tight">
            Your Email
          </p>
          <Input
            id="email"
            placeholder="name@example.com"
            type="email"
            autoCapitalize="none"
            autoComplete="email"
            autoCorrect="off"
            onChange={(event) => setEmail(event.target.value)}
            disabled={isLoading}
          />
          <p className="text-sm text-muted-foreground font-bold tracking-tight">
            Password
          </p>
          <Input
            id="password"
            placeholder="*********"
            type="password"
            autoCapitalize="none"
            autoComplete="email"
            autoCorrect="off"
            onChange={(event) => setPassword(event.target.value)}
            disabled={isLoading}
          />
        </div>
        <Button
          onClick={() => {
            setIsLoading(true);
            signIn(email, password);
          }}
          disabled={isLoading}
        >
          {isLoading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}
          Sign In
        </Button>
      </div>
      <div>
        <div className="flex space-x-1 -space-y-1">
          <Checkbox id="terms1" />
          <div className="grid grid-cols-2 space-x-12">
            <label
              htmlFor="terms1"
              className="text-sm text-muted-foreground float-left"
            >
              Remember me
            </label>
            <label
              className="text-sm font-medium peer-disabled:cursor-allowed peer-disabled:opacity-70 float-right cursor-pointer"
              onClick={() => navigate("/forgot-password")}
            >
              Forgot Password
            </label>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-background px-2 text-muted-foreground">
            Or sign in instead with
          </span>
        </div>
      </div>
      <Button
        onClick={() => signInWithGithub()}
        variant="outline"
        type="button"
        disabled={isLoading}
      >
        {isLoading ? (
          <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
        ) : (
          <Icons.gitHub className="mr-2 h-4 w-4" />
        )}{" "}
        Github
      </Button>
    </div>
  );
}
