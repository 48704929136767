import { useNavigate } from "react-router-dom";
import { SignUpForm } from "./signupForm";

//type Props = {}

const SignUp = () => {
  const navigate = useNavigate();

  return (
    <div className="container relative hidden h-screen flex-col items-center justify-center min-[320px]:grid md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
        <div className="absolute inset-0 bg-zinc-900" />
        <div className="relative z-20 flex items-center text-lg font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="mr-2 h-6 w-6"
          >
            <path d="M15 6v12a3 3 0 1 0 3-3H6a3 3 0 1 0 3 3V6a3 3 0 1 0-3 3h12a3 3 0 1 0-3-3" />
          </svg>
          Rtbot Team
        </div>
        <div className="relative z-20 mt-auto">
          <blockquote className="space-y-2">
            <p className="text-lg">&ldquo;Rtbot Image&rdquo;</p>
            <footer className="text-sm">Rtbot footer</footer>
          </blockquote>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="flex justify-center">
          <div className="mt-6 ml-6 mb-6 mr-6 flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Create an account
              </h1>
            </div>
            <div className="flex justify-center text-sm font-medium space-x-1">
              <h2 className="text-muted-foreground">Have an account??</h2>
              <label
                className="underline peer-disabled:cursor-allowed peer-disabled:opacity-70 float-right cursor-pointer"
                onClick={() => navigate("/signin")}
              >
                Sign in
              </label>
            </div>
            <SignUpForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
