import { useAppMenuStoreSlice, AppStoreApi, AppMenuStore } from "@rtbot-dev/ui-app-menu";
import {
  Instance,
  Bot,
  DataSource,
  Encoder,
  Decoder,
  Program,
  Destination,
  Dataset,
  InstanceSchema,
  DataSourceSchema,
  EncoderSchema,
  DecoderSchema,
  DestinationSchema,
  BotSchema,
  ProgramSchema,
  Deployment,
} from "@rtbot-dev/json-schemas";
import { getStorage, ref, uploadBytesResumable, FirebaseStorage, deleteObject, getBlob } from "firebase/storage";
import { app, useAuthStore } from "../auth/auth-store";
import {
  getFirestore,
  collection,
  doc,
  getDocs,
  deleteDoc,
  updateDoc,
  query,
  where,
  serverTimestamp,
  orderBy,
  setDoc,
  getDoc,
  addDoc,
} from "firebase/firestore";
import { auth } from "../auth/auth-store";
import { User } from "firebase/auth";
import Papa, { ParseMeta, ParseResult } from "papaparse";
import { format } from "date-fns";
import { create } from "zustand";
import Ajv from "ajv";

const ajv = new Ajv({ removeAdditional: true, discriminator: true });

export type DataMetadata = ParseMeta & { numRows: number; id?: string };

const { BOT_MANAGER_ENDPOINT = "https://bot-manager-zulj4gn7ka-ew.a.run.app" } = import.meta.env;

export class StudioAppStoreApi implements AppStoreApi {
  private readonly dataCache: Record<string, number[][]>;
  private readonly firestore;
  private readonly storage: FirebaseStorage;
  private user: User | null = null;

  constructor() {
    this.storage = getStorage(app);
    this.firestore = getFirestore(app);
    this.dataCache = {};

    auth.onAuthStateChanged(async (user) => {
      this.user = user;
      console.log("auth store state changed user", user);
      useAuthStore.getState().setUser(user);
    });
  }

  async deployBot(botId: string): Promise<string> {
    if (this.user === null) {
      console.error("deploy bot: user is not authenticated");
      return "";
    }

    try {
      console.log("deploying bot", botId);
      const response = await fetch(`${BOT_MANAGER_ENDPOINT}/up/${botId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await this.user.getIdToken()}`,
        },
      });
      const data = await response.json();
      console.log("deployed bot", data);
      return data.instanceId;
    } catch (e) {
      console.error("Error deploying bot", e);
      return "";
    }
  }

  async createInstance(instance: Omit<Instance, "createdAt" | "createdBy">): Promise<void> {
    if (this.user === null) {
      console.error("create instance: user is not authenticated");
      return;
    }
    try {
      await setDoc(doc(this.firestore, `instances`, instance.id), {
        ...instance,
        createdBy: this.user.uid,
        createdAt: serverTimestamp(),
      });
      console.log("instance saved with id: ", instance.id);
    } catch (e) {
      console.error("Error adding instance: ", e);
    }
  }

  async readInstance(instanceId: string): Promise<Instance | undefined> {
    if (this.user === null) {
      console.error("reading instance: user is not authenticated");
      return;
    }

    try {
      const ref = doc(this.firestore, "instances", instanceId);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        const data = docSnap.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(InstanceSchema, data);
        if (!valid) {
          throw new Error("Invalid instance: " + ajv.errorsText());
        }
        return data as Instance;
      }
    } catch (e) {
      console.error("Error while reading data source", e);
      throw e;
    }
  }

  async updateInstance(instanceId: string, partial: Partial<Instance>): Promise<void> {
    if (this.user === null) {
      console.error("updating instance: user is not authenticated");
      return;
    }

    try {
      console.log("updating", instanceId, partial);
      await updateDoc(doc(this.firestore, `instances/${instanceId}`), {
        ...partial,
        updatedBy: this.user.uid,
        updatedAt: serverTimestamp(),
      });
    } catch (e) {
      console.error("Error while updating instance", instanceId, e);
    }
  }

  async deleteInstance(instanceId: string): Promise<void> {
    if (this.user === null) {
      console.error("create instance: user is not authenticated");
      return;
    }
    try {
      await deleteDoc(doc(this.firestore, `instances/${instanceId}`));
      console.log("instance deleted", instanceId);
    } catch (e) {
      console.error("Error deleting instance: ", e);
    }
  }

  async listInstances(): Promise<{ [instanceId: string]: Instance }> {
    if (this.user === null) {
      console.error("list instances: user is not authenticated");
      return {};
    }

    try {
      const querySnapshot = await getDocs(
        query(collection(this.firestore, `instances`), where("createdBy", "==", this.user.uid), orderBy("createdAt"))
      );
      const instances: { [instanceId: string]: Instance } = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(InstanceSchema, data);
        if (!valid) {
          console.error("Invalid instance: ", data, ajv.errors);
          return;
        }
        const instance: Instance = {
          ...(data as Instance),
          id: doc.id,
          locked: false,
        };
        instances[doc.id] = instance;
      });
      return instances;
    } catch (e) {
      console.error("Error while getting the list of instances", e);
      return {};
    }
  }

  async resumeInstance(instanceId: string): Promise<void> {
    if (this.user === null) {
      console.error("resume instance: user is not authenticated");
      return;
    }

    console.log("resuming", instanceId);
    const response = await fetch(`${BOT_MANAGER_ENDPOINT}/resume/${instanceId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await this.user.getIdToken()}`,
      },
    });
    console.log("resumed", response);
    if (response.ok) {
      console.log("Instance resumed successfully");
    } else {
      console.error("Error while resuming instance", instanceId, response);
      throw new Error("Error while resuming instance");
    }
  }

  async stopInstance(instanceId: string): Promise<void> {
    if (this.user === null) {
      console.error("stop instance: user is not authenticated");
      return;
    }

    console.log("stopping", instanceId);
    const response = await fetch(`${BOT_MANAGER_ENDPOINT}/down/${instanceId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await this.user.getIdToken()}`,
      },
    });
    console.log("stopped", response);
    if (response.ok) {
      console.log("Instance stopped successfully");
    } else {
      console.error("Error while stopping instance", instanceId, response);
      throw new Error("Error while stopping instance");
    }
  }

  async readInstanceLogs(instanceId: string): Promise<Deployment[] | undefined> {
    if (this.user === null) {
      console.error("reading instance logs: user is not authenticated");
      return;
    }

    try {
      // iterate over the deployments collections and get all
      // the logs for the given instanceId and createdBy current user
      const querySnapshot = await getDocs(
        query(
          collection(this.firestore, `deployments`),
          where("instanceId", "==", instanceId),
          where("createdBy", "==", this.user.uid),
          orderBy("createdAt")
        )
      );

      const deployments: Deployment[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        deployments.push(data as Deployment);
      });
      return deployments;
    } catch (e) {
      console.error("Error while getting the list of bots", e);
      throw e;
    }
  }

  async createBot(bot: Omit<Bot, "createdAt" | "createdBy">): Promise<void> {
    if (this.user === null) {
      console.error("create bot: user is not authenticated");
      return;
    }
    try {
      await setDoc(doc(this.firestore, `bots`, bot.id), {
        ...bot,
        createdBy: this.user.uid,
        createdAt: serverTimestamp(),
      });
      console.log("bot saved with id: ", bot.id);
    } catch (e) {
      console.error("Error adding bot: ", e);
    }
  }

  async readBot(botId: string): Promise<Bot | undefined> {
    if (this.user === null) {
      console.error("reading bot: user is not authenticated");
      return;
    }

    try {
      const ref = doc(this.firestore, "bots", botId);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        const data = docSnap.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(BotSchema, data);
        if (!valid) {
          console.error("Invalid bot: ", data, ajv.errors);
          throw new Error("Invalid bot: " + ajv.errorsText());
        }
        return data as Bot;
      }
    } catch (e) {
      console.error("Error while getting the list of bots", e);
      throw e;
    }
  }

  async updateBot(botId: string, partial: Partial<Bot>): Promise<void> {
    if (this.user === null) {
      console.error("updating bot: user is not authenticated");
      return;
    }

    try {
      console.log("updating", botId, partial);
      await updateDoc(doc(this.firestore, `bots/${botId}`), {
        ...partial,
        updatedBy: this.user.uid,
        updatedAt: serverTimestamp(),
      });
    } catch (e) {
      console.error("Error while updating bot", botId, e);
    }
  }

  async deleteBot(botId: string): Promise<void> {
    if (this.user === null) {
      console.error("create bot: user is not authenticated");
      return;
    }
    try {
      await deleteDoc(doc(this.firestore, `bots/${botId}`));
      console.log("bot deleted", botId);
    } catch (e) {
      console.error("Error deleting bot: ", e);
    }
  }

  async listBots(): Promise<{ [botId: string]: Bot }> {
    if (this.user === null) {
      console.error("list bots: user is not authenticated");
      return {};
    }

    try {
      const querySnapshot = await getDocs(
        query(collection(this.firestore, `bots`), where("createdBy", "==", this.user.uid), orderBy("createdAt"))
      );
      const bots: { [botId: string]: Bot } = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(BotSchema, data);
        if (!valid) {
          console.error("Invalid bot: ", data, ajv.errors);
          return;
        }
        const bot: Bot = {
          ...(data as Bot),
          id: doc.id,
          locked: false,
        };
        bots[doc.id] = bot;
      });
      return bots;
    } catch (e) {
      console.error("Error while getting the list of bots", e);
      return {};
    }
  }

  async createDataSource(dataSource: Omit<DataSource, "createdAt" | "createdBy">): Promise<void> {
    if (this.user === null) {
      console.error("create dataSource: user is not authenticated");
      return;
    }
    try {
      await setDoc(doc(this.firestore, `dataSources`, dataSource.id), {
        ...dataSource,
        createdBy: this.user.uid,
        createdAt: serverTimestamp(),
      });
      console.log("dataSource saved with id: ", dataSource.id);
    } catch (e) {
      console.error("Error adding dataSource: ", e);
    }
  }

  async readDataSource(dataSourceId: string): Promise<DataSource | undefined> {
    if (this.user === null) {
      console.error("reading dataSource: user is not authenticated");
      return;
    }

    try {
      const ref = doc(this.firestore, "dataSources", dataSourceId);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        const data = docSnap.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(DataSourceSchema, data);
        if (!valid) {
          throw new Error("Invalid dataSource: " + ajv.errorsText());
        }
        return data as DataSource;
      }
    } catch (e) {
      console.error("Error while reading data source", e);
      throw e;
    }
  }

  async updateDataSource(dataSourceId: string, partial: Partial<DataSource>): Promise<void> {
    if (this.user === null) {
      console.error("updating dataSource: user is not authenticated");
      return;
    }

    try {
      console.log("updating", dataSourceId, partial);
      await updateDoc(doc(this.firestore, `dataSources/${dataSourceId}`), {
        ...partial,
        updatedBy: this.user.uid,
        updatedAt: serverTimestamp(),
      });
    } catch (e) {
      console.error("Error while updating dataSource", dataSourceId, e);
    }
  }

  async deleteDataSource(dataSourceId: string): Promise<void> {
    if (this.user === null) {
      console.error("create dataSource: user is not authenticated");
      return;
    }
    try {
      await deleteDoc(doc(this.firestore, `dataSources/${dataSourceId}`));
      console.log("dataSource deleted", dataSourceId);
    } catch (e) {
      console.error("Error deleting dataSource: ", e);
    }
  }

  async listDataSources(): Promise<{ [dataSourceId: string]: DataSource }> {
    if (this.user === null) {
      console.error("list dataSources: user is not authenticated");
      return {};
    }

    try {
      const querySnapshot = await getDocs(
        query(collection(this.firestore, `dataSources`), where("createdBy", "==", this.user.uid), orderBy("createdAt"))
      );
      const dataSources: { [dataSourceId: string]: DataSource } = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(DataSourceSchema, data);
        if (!valid) {
          console.error("Invalid dataSource: ", data, ajv.errors);
          return;
        }
        const dataSource: DataSource = {
          ...(data as DataSource),
          id: doc.id,
          locked: false,
        };
        dataSources[doc.id] = dataSource;
      });
      return dataSources;
    } catch (e) {
      console.error("Error while getting the list of dataSources", e);
      return {};
    }
  }

  async createEncoder(encoder: Omit<Encoder, "createdAt" | "createdBy">): Promise<void> {
    if (this.user === null) {
      console.error("create encoder: user is not authenticated");
      return;
    }
    try {
      await setDoc(doc(this.firestore, `encoders`, encoder.id), {
        ...encoder,
        createdBy: this.user.uid,
        createdAt: serverTimestamp(),
      });
      console.log("encoder saved with id: ", encoder.id);
    } catch (e) {
      console.error("Error adding encoder: ", e);
      throw e;
    }
  }

  async readEncoder(encoderId: string): Promise<Encoder | undefined> {
    if (this.user === null) {
      console.error("reading encoder: user is not authenticated");
      return;
    }

    try {
      const ref = doc(this.firestore, "encoders", encoderId);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        const data = docSnap.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(EncoderSchema, data);
        if (!valid) {
          console.error("Invalid encoder: ", data, ajv.errors);
          throw new Error("Invalid encoder: " + ajv.errorsText());
        }
        return data as Encoder;
      }
    } catch (e) {
      console.error("Error while reading encoder", e);
      throw e;
    }
  }

  async updateEncoder(encoderId: string, partial: Partial<Encoder>): Promise<void> {
    if (this.user === null) {
      console.error("updating encoder: user is not authenticated");
      return;
    }

    try {
      console.log("updating", encoderId, partial);
      await updateDoc(doc(this.firestore, `encoders/${encoderId}`), {
        ...partial,
        updatedBy: this.user.uid,
        updatedAt: serverTimestamp(),
      });
    } catch (e) {
      console.error("Error while updating encoder", encoderId, e);
    }
  }

  async deleteEncoder(encoderId: string): Promise<void> {
    if (this.user === null) {
      console.error("create encoder: user is not authenticated");
      return;
    }
    try {
      await deleteDoc(doc(this.firestore, `encoders/${encoderId}`));
      console.log("encoder deleted", encoderId);
    } catch (e) {
      console.error("Error deleting encoder: ", e);
    }
  }

  async listEncoders(): Promise<{ [encoderId: string]: Encoder }> {
    if (this.user === null) {
      console.error("list encoders: user is not authenticated");
      return {};
    }

    try {
      const querySnapshot = await getDocs(
        query(collection(this.firestore, `encoders`), where("createdBy", "==", this.user.uid), orderBy("createdAt"))
      );
      const encoders: { [encoderId: string]: Encoder } = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(EncoderSchema, data);
        if (!valid) {
          console.error("Invalid encoder: ", data, ajv.errors);
        }
        const encoder: Encoder = {
          ...(data as Encoder),
          id: doc.id,
          locked: false,
        };
        encoders[doc.id] = encoder;
      });
      return encoders;
    } catch (e) {
      console.error("Error while getting the list of encoders", e);
      return {};
    }
  }

  async createDecoder(decoder: Omit<Decoder, "createdAt" | "createdBy">): Promise<void> {
    if (this.user === null) {
      console.error("create decoder: user is not authenticated");
      return;
    }
    try {
      await setDoc(doc(this.firestore, `decoders`, decoder.id), {
        ...decoder,
        createdBy: this.user.uid,
        createdAt: serverTimestamp(),
      });
      console.log("decoder saved with id: ", decoder.id);
    } catch (e) {
      console.error("Error adding decoder: ", e);
    }
  }

  async readDecoder(decoderId: string): Promise<Decoder | undefined> {
    if (this.user === null) {
      console.error("reading decoder: user is not authenticated");
      return;
    }

    try {
      const ref = doc(this.firestore, "decoders", decoderId);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        const data = docSnap.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(DecoderSchema, data);
        if (!valid) {
          console.error("Invalid decoder: ", data, ajv.errors);
          throw new Error("Invalid decoder: " + ajv.errorsText());
        }
        return data as Decoder;
      }
    } catch (e) {
      console.error("Error while reading decoder", e);
      throw e;
    }
  }

  async updateDecoder(decoderId: string, partial: Partial<Decoder>): Promise<void> {
    if (this.user === null) {
      console.error("updating decoder: user is not authenticated");
      return;
    }

    try {
      console.log("updating", decoderId, partial);
      await updateDoc(doc(this.firestore, `decoders/${decoderId}`), {
        ...partial,
        updatedBy: this.user.uid,
        updatedAt: serverTimestamp(),
      });
    } catch (e) {
      console.error("Error while updating decoder", decoderId, e);
    }
  }

  async deleteDecoder(decoderId: string): Promise<void> {
    if (this.user === null) {
      console.error("create decoder: user is not authenticated");
      return;
    }
    try {
      await deleteDoc(doc(this.firestore, `decoders/${decoderId}`));
      console.log("decoder deleted", decoderId);
    } catch (e) {
      console.error("Error deleting decoder: ", e);
    }
  }

  async listDecoders(): Promise<{ [decoderId: string]: Decoder }> {
    if (this.user === null) {
      console.error("list decoders: user is not authenticated");
      return {};
    }

    try {
      const querySnapshot = await getDocs(
        query(collection(this.firestore, `decoders`), where("createdBy", "==", this.user.uid), orderBy("createdAt"))
      );
      const decoders: { [decoderId: string]: Decoder } = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(DecoderSchema, data);
        if (!valid) {
          console.error("Invalid decoder: ", data, ajv.errors);
          throw new Error("Invalid decoder: " + ajv.errorsText());
        }
        const decoder: Decoder = {
          ...(data as Decoder),
          id: doc.id,
          locked: false,
        };
        decoders[doc.id] = decoder;
      });
      return decoders;
    } catch (e) {
      console.error("Error while getting the list of decoders", e);
      return {};
    }
  }

  async createDestination(destination: Omit<Destination, "createdAt" | "createdBy">): Promise<void> {
    if (this.user === null) {
      console.error("create destination: user is not authenticated");
      return;
    }
    try {
      await setDoc(doc(this.firestore, `destinations`, destination.id), {
        ...destination,
        createdBy: this.user.uid,
        createdAt: serverTimestamp(),
      });
      console.log("destination saved with id: ", destination.id);
    } catch (e) {
      console.error("Error adding destination: ", e);
    }
  }

  async readDestination(destinationId: string): Promise<Destination | undefined> {
    if (this.user === null) {
      console.error("reading destination: user is not authenticated");
      return;
    }

    try {
      const ref = doc(this.firestore, "destinations", destinationId);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        const data = docSnap.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(DestinationSchema, data);
        if (!valid) {
          console.error("Invalid destination: ", data, ajv.errors);
          throw new Error("Invalid destination: " + ajv.errorsText());
        }
        return data as Destination;
      }
    } catch (e) {
      console.error("Error while reading destination", e);
      throw e;
    }
  }

  async updateDestination(destinationId: string, partial: Partial<Destination>): Promise<void> {
    if (this.user === null) {
      console.error("updating destination: user is not authenticated");
      return;
    }

    try {
      console.log("updating", destinationId, partial);
      await updateDoc(doc(this.firestore, `destinations/${destinationId}`), {
        ...partial,
        updatedBy: this.user.uid,
        updatedAt: serverTimestamp(),
      });
    } catch (e) {
      console.error("Error while updating destination", destinationId, e);
    }
  }

  async deleteDestination(destinationId: string): Promise<void> {
    if (this.user === null) {
      console.error("create destination: user is not authenticated");
      return;
    }
    try {
      await deleteDoc(doc(this.firestore, `destinations/${destinationId}`));
      console.log("destination deleted", destinationId);
    } catch (e) {
      console.error("Error deleting destination: ", e);
    }
  }

  async listDestinations(): Promise<{ [destinationId: string]: Destination }> {
    if (this.user === null) {
      console.error("list destinations: user is not authenticated");
      return {};
    }

    try {
      const querySnapshot = await getDocs(
        query(collection(this.firestore, `destinations`), where("createdBy", "==", this.user.uid), orderBy("createdAt"))
      );
      const destinations: { [destinationId: string]: Destination } = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(DestinationSchema, data);
        if (!valid) {
          console.error("Invalid destination: ", data, ajv.errors);
        }
        const destination: Destination = {
          ...(data as Destination),
          id: doc.id,
          locked: false,
        };
        destinations[doc.id] = destination;
      });
      return destinations;
    } catch (e) {
      console.error("Error while getting the list of destinations", e);
      return {};
    }
  }

  async createProgram(program: Omit<Program, "createdAt" | "createdBy">): Promise<void> {
    if (this.user === null) {
      console.error("create program: user is not authenticated");
      return;
    }
    try {
      await setDoc(doc(this.firestore, `programs`, program.id), {
        ...program,
        createdBy: this.user.uid,
        createdAt: serverTimestamp(),
      });
      console.log("program saved with id: ", program.id);
    } catch (e) {
      console.error("Error adding program: ", e);
    }
  }

  async readProgram(programId: string): Promise<Program | undefined> {
    if (this.user === null) {
      console.error("reading program: user is not authenticated");
      return;
    }

    try {
      const ref = doc(this.firestore, "programs", programId);
      const docSnap = await getDoc(ref);
      if (docSnap.exists()) {
        const data = docSnap.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(ProgramSchema, data);
        if (!valid) {
          console.error("Invalid program: ", data, ajv.errors);
          throw new Error("Invalid program: " + ajv.errorsText());
        }
        return data as Program;
      }
    } catch (e) {
      console.error("Error while getting the list of programs", e);
      throw e;
    }
  }

  async updateProgram(programId: string, partial: Partial<Program>): Promise<void> {
    if (this.user === null) {
      console.error("updating program: user is not authenticated");
      return;
    }

    try {
      console.log("updating", programId, partial);
      await updateDoc(doc(this.firestore, `programs/${programId}`), {
        ...partial,
        updatedBy: this.user.uid,
        updatedAt: serverTimestamp(),
      });
    } catch (e) {
      console.error("Error while updating program", programId, e);
    }
  }

  async deleteProgram(programId: string): Promise<void> {
    if (this.user === null) {
      console.error("create program: user is not authenticated");
      return;
    }
    try {
      await deleteDoc(doc(this.firestore, `programs/${programId}`));
      console.log("program deleted", programId);
    } catch (e) {
      console.error("Error deleting program: ", e);
    }
  }

  async listPrograms(): Promise<{ [programId: string]: Program }> {
    if (this.user === null) {
      console.error("list programs: user is not authenticated");
      return {};
    }

    try {
      const querySnapshot = await getDocs(
        query(collection(this.firestore, `programs`), where("createdBy", "==", this.user.uid), orderBy("createdAt"))
      );
      const programs: { [programId: string]: Program } = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // validate and sanitize the data, notice that `validate` here mutates the data
        const valid = ajv.validate(ProgramSchema, data);
        if (!valid) {
          console.error("Invalid program: ", data, ajv.errors);
        }
        const program: Program = {
          ...(data as Program),
          id: doc.id,
          locked: false,
        };
        programs[doc.id] = program;
      });
      return programs;
    } catch (e) {
      console.error("Error while getting the list of programs", e);
      return {};
    }
  }

  async createDataset(file: File, setUploadProgress: (progress: number) => void): Promise<void> {
    if (this.user === null) return;

    let parseMeta: DataMetadata;
    try {
      const data: ParseResult<any> = await new Promise((resolve, reject) => {
        Papa.parse(file, {
          worker: true,
          complete(result) {
            resolve(result);
          },
          error(error: Error) {
            reject(error);
          },
        });
      });
      parseMeta = { ...data.meta, numRows: data.data.length };
      console.log("csv metadata", parseMeta);
    } catch (e) {
      console.log("File is not valid csv document");
    }

    const pathRef = `${this.user.uid}/${format(new Date(), "yyyy/MM/dd")}/${Date.now()}_${file.name}`;
    const storageRef = ref(this.storage, pathRef);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = snapshot.bytesTransferred / snapshot.totalBytes;
        setUploadProgress(progress);
        console.log("Upload is " + progress);
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      async () => {
        setUploadProgress(1);
        try {
          const docRef = await addDoc(collection(this.firestore, `data`), {
            name: file.name.replace(".csv", ""),
            metadata: parseMeta,
            pathRef,
            size: file.size,
            createdBy: this.user!.uid,
            createdAt: serverTimestamp(),
          });
          console.log("Data saved with id: ", docRef.id);
        } catch (e) {
          console.error("Error adding data: ", e);
        }
      }
    );
    await new Promise((resolve) => uploadTask.then(resolve));
  }

  async loadDataset(datasetId: string): Promise<number[][] | undefined> {
    if (this.dataCache[datasetId]) {
      console.log("Using cached version of data", datasetId);
      return this.dataCache[datasetId];
    }
    if (this.user === null) return;

    try {
      // get the pathRef associated to the data id
      const dbRef = await getDoc(doc(this.firestore, `data/${datasetId}`));
      const { pathRef } = dbRef.data() as { pathRef: string };
      // delete the actual file
      const csvBlob = await getBlob(ref(this.storage, pathRef));

      const data: number[][] = await new Promise(async (resolve, reject) => {
        Papa.parse(await csvBlob.text(), {
          worker: true,
          // TODO: for some reason this doesn't work
          // header: true,
          complete(result: ParseResult<string[]>) {
            console.log("Parse result", result);
            // parse the result with the expected format for the columns:
            // first colum is an integer representing the timestamp
            // and remaining ones are considered numeric columns
            const numeric = result.data
              .filter((r: string[]) => !isNaN(r[0] as any) && r.length > 1)
              .map((r) => [parseInt(r[0]), ...r.slice(1).map((v) => parseFloat(v))]);
            resolve(numeric);
          },
          error(error: Error) {
            reject(error);
          },
        });
      });
      this.dataCache[datasetId] = data;
      console.log("Data downloaded and cached", datasetId, data);
      return data;
    } catch (e) {
      console.error("Error loading data: ", e);
    }
  }

  async readDataset(datasetId: string): Promise<Dataset | undefined> {
    console.log("Read dataset not implemented");
  }

  async updateDataset(datasetId: string, partial: Partial<Dataset>): Promise<void> {
    if (this.user === null) {
      console.error("updating program: user is not authenticated");
      return;
    }

    try {
      console.log("updating", datasetId, partial);
      await updateDoc(doc(this.firestore, `data/${datasetId}`), {
        ...partial,
        updatedBy: this.user.uid,
        updatedAt: serverTimestamp(),
      });
    } catch (e) {
      console.error("Error while updating dataset", datasetId, e);
    }
  }

  async deleteDataset(datasetId: string): Promise<void> {
    if (this.user === null) {
      console.error("Delete data: user is not authenticated");
      return;
    }
    try {
      // get the pathRef associated to the data id
      const dbRef = await getDoc(doc(this.firestore, `data/${datasetId}`));
      const { pathRef } = dbRef.data() as unknown as { pathRef: string };
      // delete the actual file
      await deleteObject(ref(this.storage, pathRef));
      // delete reference in database
      await deleteDoc(doc(this.firestore, `data/${datasetId}`));
      console.log("Data deleted", datasetId);
    } catch (e) {
      console.error("Error deleting data: ", e);
    }
  }

  async listDatasets(): Promise<{ [datasetId: string]: Dataset }> {
    if (this.user === null) {
      console.error("list projects: user is not authenticated");
      return {};
    }

    try {
      const querySnapshot = await getDocs(
        query(collection(this.firestore, `data`), where("createdBy", "==", this.user.uid), orderBy("createdAt"))
      );
      const datasets: { [datasetId: string]: Dataset } = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const project: Dataset = {
          ...(data as Dataset),
          id: doc.id,
          name: data.name ?? data.title,
          locked: false,
        };
        datasets[doc.id] = project;
      });
      return datasets;
    } catch (e) {
      console.error("Error while getting the list of datasets", e);
      return {};
    }
  }
}

const appStoreApi: AppStoreApi = new StudioAppStoreApi();

// see https://docs.pmnd.rs/zustand/guides/typescript#middleware-that-changes-the-store-type
// @ts-ignore
const appMenuStoreApiMiddleware = (f) => (set, get, store) => {
  return f(set, get, appStoreApi, store);
};

export const useAppMenuStore = create<AppMenuStore>(appMenuStoreApiMiddleware(useAppMenuStoreSlice));
