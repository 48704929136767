import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { StudioRoutes } from "./routes";

console.log("RtBot Platform Version", import.meta.env);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <StudioRoutes />
    <div className="fixed left-2 bottom-2 text-xs font-thin">
      version: {import.meta.env.VITE_RTBOT_VERSION}
    </div>
  </React.StrictMode>
);
