import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ConfirmationPage from "./auth/confirmation-page/ConfirmationPage";
import ForgotPassword from "./auth/forgot-password/ForgotPassword";
import { Workspace } from "./workspace/Workspace";
import SignIn from "./auth/signin/SignIn";
import SignUp from "./auth/signup/SignUp";
import { useAppMenuStore } from "./workspace/studio-app-menu.store";
import {
  BotView,
  DataSourceView,
  DecoderView,
  EncoderView,
  DestinationView,
  ProgramView,
  InstanceView,
} from "@rtbot-dev/ui-entities";
import { Dashboard } from "./workspace/Dashboard";

export const StudioRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/workspace" />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/confirmation-page" element={<ConfirmationPage />} />
        <Route path="/workspace" element={<Workspace />}>
          <Route path="" element={<Dashboard />} />
          <Route
            path="instances"
            element={<InstanceView useStore={useAppMenuStore} />}
          />
          <Route path="bots" element={<BotView useStore={useAppMenuStore} />} />
          <Route
            path="data-sources"
            element={<DataSourceView useStore={useAppMenuStore} />}
          />
          <Route
            path="decoders"
            element={<DecoderView useStore={useAppMenuStore} />}
          />
          <Route
            path="encoders"
            element={<EncoderView useStore={useAppMenuStore} />}
          />
          <Route
            path="destinations"
            element={<DestinationView useStore={useAppMenuStore} />}
          />
          <Route
            path="programs"
            element={<ProgramView useStore={useAppMenuStore} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
