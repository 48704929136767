import { create, StoreApi, UseBoundStore } from "zustand";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GithubAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
} from "firebase/auth";

const firebaseConfig = {
  // DO NOT COMMIT
  apiKey: "AIzaSyCQ_rmvrAojerwLyN_9khdJzDguuuc1mxk",
  authDomain: "rtbot-6515d.firebaseapp.com",
  projectId: "rtbot-6515d",
  storageBucket: "rtbot-6515d.appspot.com",
  messagingSenderId: "454936526147",
  appId: "1:454936526147:web:fda411d0b1ba72b35743f6",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

const githubProvider = new GithubAuthProvider();

export type AuthStoreState = {
  loading: boolean;
  user: User | null;
};

export type AuthStoreActions = {
  setUser: (user: User | null) => void;
  signUp: (email: string, password: string) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signInWithGithub: () => Promise<void>;
  signOut: () => Promise<void>;
};

export type AuthStore = AuthStoreState & AuthStoreActions;

export const useAuthStore: UseBoundStore<StoreApi<AuthStore>> =
  create<AuthStore>((set, get) => ({
    loading: true,
    user: null,
    setUser: (user: User | null) => {
      console.log("setUser", user);
      set(() => ({
        user,
        loading: false,
      }));
    },
    async signUp(email: string, password: string) {
      set(() => ({ loading: true }));
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          console.log("Signed up", userCredential);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(
            `Unable to create user, error code: ${errorCode}, ${errorMessage}`
          );
        })
        .finally(() => set(() => ({ loading: false })));
    },
    async signIn(email: string, password: string) {
      set(() => ({ loading: true }));
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          console.log("Signed in", userCredential);
        })
        .catch((error) => {
          console.error(
            `Unable to sign in user, error code: ${error.code}, ${error.message}`
          );
        })
        .finally(() => set(() => ({ loading: false })));
    },
    async signInWithGithub() {
      set(() => ({ loading: true }));
      signInWithPopup(auth, githubProvider)
        .then((userCredential) => {
          // Signed in
          console.log("Signed in with github", userCredential);
        })
        .catch((error) => {
          console.error(
            `Unable to sign in user, error code: ${error.code}, ${error.message}`
          );
        })
        .finally(() => set(() => ({ loading: false })));
    },
    async signOut() {
      set(() => ({ loading: true }));
      auth
        .signOut()
        .then(() => {
          console.log("Signed out successfully");
          set(() => ({ user: null }));
        })
        .catch((error) => {
          // errorStore.setError(error);
          console.error("Unable to sign out", error.code, error.message);
        })
        .finally(() => set(() => ({ loading: false })));
    },
  }));
