import { useAuthStore } from "@/auth/auth-store";
import { AppMenu } from "@rtbot-dev/ui-app-menu";
import { useEffect, useRef } from "react";
import "react-grid-layout/css/styles.css";
import { Outlet, useNavigate } from "react-router-dom";

import { useAppMenuStore } from "./studio-app-menu.store";
import { useLocation } from "react-router-dom";
import { Header } from "./header/Header";

export const Workspace = () => {
  const location = useLocation();
  const { pathname } = location;

  const navigate = useNavigate();
  const { user, loading } = useAuthStore((state) => state);
  const {
    listInstances,
    listPrograms,
    listDatasets,
    listBots,
    listDataSources,
    listDecoders,
    listDestinations,
    listEncoders,
  } = useRef(useAppMenuStore((state) => state)).current;

  // TODO: for some reason we are having several renders of this component
  //console.log("Workspace user", user, "loading", loading);
  useEffect(() => {
    console.log("[Workspace] user", user, "loading", loading);
    if (!loading && user === null) {
      navigate("/signin");
    }

    if (user !== null && !loading)
      Promise.all([
        listInstances(),
        listPrograms(),
        listDatasets(),
        listBots(),
        listDataSources(),
        listDecoders(),
        listDestinations(),
        listEncoders(),
      ]).then(() => {
        console.log("All data fetched");
      });
  }, [
    user,
    loading,
    listPrograms,
    listDatasets,
    listBots,
    listDataSources,
    listDecoders,
    listDestinations,
    listEncoders,
    navigate,
  ]);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <Header />
      <div className="grid grid-cols-10 gap-1">
        <div className="col-span-2 xs:col-span-4 bg-neutral-50">
          <AppMenu
            useStore={useAppMenuStore}
            createBot={() => navigate("/workspace/bots?action=create")}
            createDataSource={() =>
              navigate("/workspace/data-sources?action=create")
            }
            createDecoder={() => navigate("/workspace/decoders?action=create")}
            createProgram={() => navigate("/workspace/programs?action=create")}
            createEncoder={() => navigate("/workspace/encoders?action=create")}
            createDestination={() =>
              navigate("/workspace/destinations?action=create")
            }
            readBot={(id) => navigate(`/workspace/bots?id=${id}&action=read`)}
            readDataSource={(id) =>
              navigate(`/workspace/data-sources?id=${id}&action=read`)
            }
            readDecoder={(id) =>
              navigate(`/workspace/decoders?id=${id}&action=read`)
            }
            readProgram={(id) =>
              navigate(`/workspace/programs?id=${id}&action=read`)
            }
            readEncoder={(id) =>
              navigate(`/workspace/encoders?id=${id}&action=read`)
            }
            readDestination={(id) =>
              navigate(`/workspace/destinations?id=${id}&action=read`)
            }
            readDataset={(id) => console.log("Updating dataset", id)}
            readInstance={(id) => {
              navigate(`/workspace/instances?id=${id}&action=read`);
            }}
          />
        </div>
        <div
          className={
            // use all space for certain views
            pathname === "/workspace/programs" || pathname === "/workspace"
              ? "col-start-3 col-end-11 pt-8 px-8"
              : "col-start-4 col-end-8 pt-8"
          }
        >
          {user !== null && !loading ? <Outlet /> : "please wait"}
        </div>
      </div>
      {/* this is just to force tailwind to include these classes which are used in child components*/}
      <div className="font-thin font-light"></div>
    </div>
  );
};
