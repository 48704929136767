import { Link } from "react-router-dom";
import { UserMenu } from "./UserMenu";

export const Header = () => {
  return (
    <>
      <div className="flex-col md:flex bg-rtbot-400">
        <div className="border-b">
          <div className="flex h-16 items-center pr-2 sm:pr-8">
            <div className="relative flex flex-1 align-top items-center justify-start">
              <div className="sm:ml-2 sm:block">
                <div className="flex space-x-4">
                  <Link
                    className="text-sm font-medium transition-colors hover:text-primary"
                    to="/"
                  >
                    <img src="/rtbot-logo.svg" alt="RTBot" className="h-8" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="ml-auto flex items-center space-x-4">
              <UserMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
