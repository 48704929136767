import { SignInForm } from "./signinForm";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const navigate = useNavigate();

  return (
    <div className="container relative hidden h-screen flex-col items-center justify-center md:grid">
      <div className="lg:p-8 ">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6">
          <div className="flex flex-col space-y-2 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              Sign in to your account
            </h1>
          </div>
          <div className="flex justify-center text-sm font-medium space-x-1">
            <h2 className="text-muted-foreground">Not have an account??</h2>
            <label
              className="underline peer-disabled:cursor-allowed peer-disabled:opacity-70 float-right cursor-pointer"
              onClick={() => navigate("/signup")}
            >
              Create an account
            </label>
          </div>
          <SignInForm />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
