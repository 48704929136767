import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Icons } from "@/components/ui/icons";
import { cn } from "@/components/ui/utils";

interface ForgotPasswordProps extends React.HTMLAttributes<HTMLDivElement> {}

export function ForgotPassword({ className, ...props }: ForgotPasswordProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }
  const navigate = useNavigate();

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <div className="container relative h-[80px] flex-col mx-1 md:grid lg:max-w-none lg:px-0 sm:w-[480px]">
        <div className="items-center flex-col justify-center">
          <div className="flex w-full flex-col">
            <div className="flex flex-col space-y-2 text-left">
              <h1 className="text-2xl font-semibold tracking-tight py-2">
                Reset Password Using Email
              </h1>
            </div>
            <p className="text-sm  space-y-10 after:text-muted-foreground font-bold tracking-tight py-2">
              Email Address
            </p>
            <form onSubmit={onSubmit}>
              <div className="grid gap-2 items-left ">
                <Input
                  id="email"
                  placeholder="name@example.com"
                  type="email"
                  autoCapitalize="none"
                  autoComplete="email"
                  autoCorrect="off"
                  disabled={isLoading}
                />
              </div>
            </form>
          </div>
          <div className="flex float-right py-2">
            <Button
              disabled={isLoading}
              onClick={() => navigate("/confirmation-page")}
            >
              {isLoading && (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin items-center" />
              )}
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
