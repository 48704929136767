import { useNavigate } from "react-router-dom";

const ConfirmationPage = () => {
  const navigate = useNavigate();

  return (
    <div className="container relative h-[80px] flex-col min-[320px]:grid md:grid lg:max-w-none lg:grid-cols-2 lg:px-0 w-[(100%,38rem)]">
      <div className="items-center flex-col justify-center w-full">
        <div className="flex w-full flex-col">
          <div className="flex flex-col space-y-2 text-left">
            <h1 className="text-2xl font-bold tracking-tight py-4">
              Reset Password Using Email
            </h1>
          </div>
          <p className="text-sm  space-y-10 text-muted-foreground tracking-tight py-2">
            An email with a reset password link has been sent to your registered
            email address. Please verify to this email to continue the password
            reset process.
          </p>
        </div>
        <div className="flex float-right py-2">
          <label
            className="underline text-sm font-medium peer-disabled:cursor-allowed peer-disabled:opacity-70 float-right cursor-pointer"
            onClick={() => navigate("/signin")}
          >
            Back to Sign in
          </label>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
